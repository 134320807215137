<template>
  <div id="app">
    <img class="logo" src="/img/logo.svg" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
};
</script>

<style>
html {
  background-image: url("/img/dachstein_lake_blurred.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  backdrop-filter: blur(5px); 
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
